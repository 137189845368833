import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'

/* Third Party Global Components */
import ThirdParty from '@components/third-party/ThirdParty'
import ReCAPTCHA from '@components/third-party/reCAPTCHA/reCAPTCHA'

/* Hooks */
import { useFirstRenderOnScroll } from '@hooks/useFirstRenderOnScroll'
import { useHasCookie, useIncrementCookie } from '@hooks/useCookieHooks'
import { useDisablePrompts } from '@hooks/useDisablePrompts'

/* Custom Global Components */
const SubscribeModal = lazy(() =>
	import('@components/subscribe-modal/SubscribeModal').catch(() => {
		return { default: () => <></> }
	})
)

/* Layout Catch-All Styles */
import './SiteTemplate.scss'

/* Global Inline Styles */
import normalizeStyles from '!raw-loader!postcss-loader!sass-loader!modern-normalize/modern-normalize.css'
import globalStyles from '!raw-loader!postcss-loader!sass-loader!../../scss/global/global.scss'
import restStyles from '!raw-loader!postcss-loader!sass-loader!../../scss/global/reset.scss'
import utilitiesStyles from '!raw-loader!postcss-loader!sass-loader!../../scss/global/utilities.scss'
import pleaseWaitStyles from '!raw-loader!postcss-loader!sass-loader!../../scss/effects/pleaseWait.scss'

/* Third Party Styles */
import recaptchaStyles from '!raw-loader!postcss-loader!sass-loader!../../../lib/scss/third-party/grecaptcha.scss'

/* Effect Styles */
import effectStyles from '!raw-loader!postcss-loader!sass-loader!../../../lib/scss/effects/bodyStuck.scss'

const SiteTemplate = ({
	children,
	thirdParty,
}: {
	children: React.ReactNode
	thirdParty: { [key: string]: string }
}): React.ReactElement => {
	const disablePrompts = useDisablePrompts()

	/* Render subscribe modal if scroll interaction detected & no existing subscribe cookie */
	const hasCookie = useHasCookie('subscribe', undefined, true)
	const render = useFirstRenderOnScroll(300)

	/* Track page count to render subscribe modal after 2+ pages */
	const pageCounter: string = 'pageCounter'
	const pageCounterMin: number = 2
	useIncrementCookie(pageCounter, pageCounterMin)
	const hasPageCounter = useHasCookie(pageCounter, pageCounterMin, true, true)

	return (
		<>
			<Helmet>
				<style id="css-SiteTemplate">
					{[
						effectStyles,
						recaptchaStyles,
						normalizeStyles,
						globalStyles,
						restStyles,
						utilitiesStyles,
						pleaseWaitStyles,
					].join('')}
				</style>
			</Helmet>
			<ThirdParty thirdParty={thirdParty} />
			<ReCAPTCHA />
			{children}
			{!disablePrompts && render && !hasCookie && hasPageCounter && (
				<Suspense fallback={<></>}>
					<SubscribeModal />
				</Suspense>
			)}
		</>
	)
}

export default SiteTemplate
